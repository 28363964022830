.voucherPage-style{
  background-color: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.voucher-tytle-box{
  -webkit-box-shadow: 1px 3px 3px 2px rgba(0,0,0,0.08); 
  box-shadow: 1px 3px 3px 2px rgba(0,0,0,0.08);
}

.voucher-form-style{
  background-color: rgba(255, 255, 255, 0.85);
  padding: 1rem;
  -webkit-box-shadow: 1px 3px 15px 2px rgba(0,0,0,0.30); 
  box-shadow: 1px 3px 15px 2px rgba(0,0,0,0.30);
  margin-top: 0.5rem;
  border-radius: 10px;
  width: 860px;
}