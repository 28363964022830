.consumption-style{
  background-color: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding-left: 2rem;
  padding-right: 2rem;
}

.consumptionTable-style{
  -webkit-box-shadow: 1px 3px 3px 2px rgba(0,0,0,0.15); 
  box-shadow: 1px 3px 3px 2px rgba(0,0,0,0.15);
  width: 95%;
}

.day-w{
  width: 70px;
  height: 30px;
}

.name-w{
  width: 175px;
}

.w-300{
  width: 300px;
}

.informe-style{
  -webkit-box-shadow: 1px 3px 3px 2px rgba(0,0,0,0.05); 
  box-shadow: 1px 3px 3px 2px rgba(0,0,0,0.05);
}
.informe-style:hover{
  background-color: rgba(0, 0, 0, 0.1);
}