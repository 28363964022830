.stockOut-style{
  padding-top: 2rem;
  background-color: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.stockOut-form{
  width: 600px;
}

.w-500{
  width: 300px;
}