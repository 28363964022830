.ordersPage-style{
  background-color: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.btn-active{
  background-color: #747474;
  color: whitesmoke;
  cursor: default;
}

.btn-inactive:hover{
  transition: 300ms;
  background-color: rgba(0, 0, 0, 0.15);
}

.ordersBox-style{
  width: 100%;
}

.reload-style:hover{
  transition: 300ms;
  background-color: rgba(0, 0, 0, 0.15)
}
