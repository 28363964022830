.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s;
  z-index: 201;
}

.popup {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.popup h2 {
  margin: 0 0 10px;
}

.popup p {
  margin: 10px 0;
}

.popup .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.popUpBtn-style {
  padding: 10px 20px;
  border: none;
  background-color: rgba(9, 82, 86, 0.8);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.popUpBtn-style:hover {
  transition: 300ms;
  background-color: var(--deep-jungle-green);
}

#openPopup {
  position: absolute;
  bottom: 20px;
}

.overlay.active {
  visibility: visible;
  opacity: 1;
}

.bold-border {
  border-width: 3px !important;
}