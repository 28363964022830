.stockReadPage-style{
  background-color: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.wpx-60{
  width: 90px;
}

.hpx-40{
  height: 40px;
}

.bg-decomp{
  background-color: #E9ECEF !important; 
}

.waste-btn:hover{
  -webkit-box-shadow: 3px 3px 7px 3px rgba(0,0,0,0.12); 
  box-shadow: 3px 3px 7px 3px rgba(0,0,0,0.12);
}

.z-50{
  z-index: 50;
}