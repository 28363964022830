.thick-border{
  border-width: 2px !important;
}

.delete-item_disabled{
  color: grey;
  cursor: auto;
}

.pending-btn:hover{
  background-color: #254CFC;
  border-color: #254CFC;
  transition: 300ms;
  color: white;
}