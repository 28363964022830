.logistic-style{
  background-color: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding-left: 2rem;
  padding-right: 2rem;
}

.btn-active{
  background-color: #747474;
  color: whitesmoke;
  cursor: default !important;
}

.dataTable-style{
  width: 700px;
}