.devicePage-style{
  background-color: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.device-body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
}

.container {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.content h1 {
  font-size: 48px;
  margin-bottom: 10px;
}

.content p {
  font-size: 18px;
  margin: 10px 0;
}

.content img {
  width: 200px;
  height: auto;
  margin: 20px 0;
}

.content button {
  background-color: #009879;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.content button:hover {
  background-color: #007d67;
}