.select-style{
  width: 100;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.table-container {
overflow-x: auto;
}

.modern-table {
width: 100%;
border-collapse: collapse;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
background-color: #fff;
border-radius: 8px;
overflow: hidden;
}

.modern-table th, .modern-table td {
padding: 15px;
text-align: left;
}

.modern-table th {
background-color: #F1F1F1;
color: black;
font-weight: bold;
border-bottom: 2px solid black;
}

.modern-table tr:nth-child(even) {
background-color: #f3f3f3;
}

.modern-table tr:hover {
background-color: #f1f1f1;
}

.success-animation_active{
transition: all 0.5s ease-in-out;
background-color: #198754 !important;
}

.spinner-table_off{
visibility: hidden;
}

.row-animation {
border: #198754 solid 2px !important;
}

.row-error{
  border: #DC3545 solid 2px !important;
}


.input-error:focus-visible{
  outline: #DC3545 auto 1px !important;
}

.w-80{
  width: 80px !important;
}