.updatePrices-style{
  background-color: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.updateBtnPrices-style{
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  bottom: -100vh;
  -webkit-box-shadow: 1px 3px 15px 2px rgba(0,0,0,0.83); 
  box-shadow: 1px 3px 15px 2px rgba(0,0,0,0.83);
  width: 350px;
  cursor: pointer;
  z-index: 1;
}

.updateBtnPrices-style_active{
  transition: 300ms;
  bottom: 0.75rem;
}

.w-80{
  width: 80px;
}

.profitForm-style{
  background-color: rgba(255, 255, 255, 0.85);
  padding: 1rem;
  -webkit-box-shadow: 1px 3px 15px 2px rgba(0,0,0,0.50); 
  box-shadow: 1px 3px 15px 2px rgba(0,0,0,0.50);
  margin-top: 0.5rem;
  border-radius: 10px;
}

.more-btn:hover{
  transition: 300ms;
  background-color: rgba(25, 135, 84, 0.5);
}

.form-data{
  display: flex;
  justify-content: space-between;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s;
}

.form-data_active{
  visibility: visible;
  opacity: 1;
}

.res-btn{
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s;
}

.res-btn_active{
  visibility: visible;
  opacity: 1;
}