.wpx-80{
  width: 80px;
}

.wpx-100{
  width: 100px;
}

.wpx-170{
  width: 170px;
}