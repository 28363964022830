.shadow-style{
  -webkit-box-shadow: 1px 3px 15px 2px rgba(0,0,0,0.83); 
  box-shadow: 1px 3px 15px 2px rgba(0,0,0,0.83);
}

.w-10{
  width: 40px;
}

.updateBtn-style-static{
  -webkit-box-shadow: 1px 3px 15px 2px rgba(0,0,0,0.1); 
  box-shadow: 1px 3px 15px 2px rgba(0,0,0,0.1);
  cursor: pointer;
  color: var(--deep-jungle-green);
}