.select-style{
  width: 100;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.input-error:focus-visible{
  outline: #DC3545 auto 1px !important;
}

.w-80{
  width: 80px !important;
}