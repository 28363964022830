.addressViewPage-style{
  background-color: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.w-40px{
  width: 40px;
}

.addressViewTable-style{
  width: 98%;
  margin-left: auto;
  margin-right: auto;
}