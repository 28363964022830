@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&family=Mulish&family=Open+Sans&family=Roboto+Mono:wght@200&display=swap');

:root{
  --deep-jungle-green: #095256ff;
  --green-lighter: #EFFDE8;
  --dark-sienna: #330f0aff;
  --green-sheen: #7daf9cff;
  --mint-green: #a2faa3ff;
  --platinum: #ebebebff;
  --green-light: #BCE49C;
  --general-bg: #e9f5db;
  --header-bg: #F9F6F4;
  
  --market-font: 'Mulish', sans-serif;
  --market-font-sup: 'Open Sans', sans-serif;
  --market-title: 'Black Han Sans', sans-serif;
  }

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pointer{
  cursor: pointer;
}

.form-spinner{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  left: 0;
  top: 0;
  /* border-radius: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}

/*ANULO LAS FLECHITAS DE LOS INPUT NUMBERS*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}