.voucherTable-style{
  width: 600px;
}

.bg-valid{
  background-color: rgba(23, 125, 78, 0.5) !important;
}


.bg-notvalid{
  background-color: rgba(209, 50, 65, 0.5) !important;
}