.admin-list-style{
  border-radius: 10px !important;
  -webkit-box-shadow: 4px 4px 15px 0px rgba(0,0,0,0.63); 
  box-shadow: 4px 4px 15px 0px rgba(0,0,0,0.63);
  width: 600px;
  z-index: 1 !important;
}

.home-items{
  text-align: center;
  cursor: pointer;
}

.op-box{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0);
  opacity: 0;
}

.op-box:hover{
  opacity: 0.15;
  transition: 300ms;
}