.layout-style{
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 350px;
  background-position: center;
  position: relative;
}

.logout-style{
  position: absolute;
  right: 0;
  top: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.12); 
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.12);
  cursor: pointer;
  border: 2px rgba(173, 173, 173, 0.678) solid;
}

.logout-style:hover{
  transition: 300ms;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.05); 
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.05);
  border: 2px rgba(173, 173, 173, 0) solid;
}