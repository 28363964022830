.sheet{
  margin: 0;
  width: 100%;
  height: 500px;
  position: fixed;
  top: 100%;
  transition: top 300ms;
}

.sheet_active{
  top: 50%;
}