.order-table-style{
  width: 300px;
  height: fit-content !important;
}

.updateOrder-btn:hover{
  transition: 500ms;
  background-color: rgb(238, 238, 0) !important;
}

.price-width{
  width: 50px;
}

.h-24{
  height: 40.8px;
}

.w-300px{
  max-width: 300px !important;
  text-wrap: wrap;
  word-break: break-all;
  border-top: none !important;
}