.wpx-40{
  width: 40px;
}

.wpx-50{
  width: 50px;
}

.wpx-60{
  width: 60px;
}

.wpx-80{
  width: 80px;
}

.hpx-50{
  height: 50px !important;
}

.wpx-240{
  width: 240px;
}

.wpx-90{
  width: 90px;
}

.hpx-35{
  height: 35px;
}

.wpx-100{
  width: 100px;
}

.wpx-70{
  width: 70px;
}

.suggestion-row{
  padding: 0.75rem !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  margin: 0 !important;
}

.delete-item{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.06); 
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.06);
  height: 100%;
}

.delete-item:hover{
  transition: 300ms;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.00); 
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.00);
}

.delete-edition{
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 0.25rem;
  cursor: pointer;
}

.profit-label{
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.07); 
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.07);
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-spinner-effect{
  animation: blink 2s ease-in-out infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
