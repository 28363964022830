.stock-loader {
  width: 8px !important;
  height: 8px !important;
  display: inline-block;
  border-radius: 50%;
  background-color: black;
  box-shadow: 16px 0 black, -16px 0 black;
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: #FFF2;
    box-shadow: 16px 0 #FFF2, -16px 0 black;
  }
  50% {
    background-color: black;
    box-shadow: 16px 0 #FFF2, -16px 0 #FFF2;
  }
  100% {
    background-color: #FFF2;
    box-shadow: 16px 0 black, -16px 0 #FFF2;
  }
}