.loginForm-style{
  border-radius: 20px;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.66); 
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.66);
  padding: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: #F8F9FA;
}

.thick-border{
  border-width: 2px !important;
  border-style: solid !important;
}